import './App.css';
import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from "react-router";
import Home from './Home';
import KohaCoffee from './KohaCoffee';

import React, { createContext, useEffect, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollContext = createContext({});

const ScrollReset = () => {

	const location = useLocation();
	// Scroll to top if path changes
	useLayoutEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);

	return null;
}

const App = ()=>{

	const [ scroll, setScroll] = useState(window.scrollY);

	const onScroll = ()=>{
		const pos = window.scrollY;
		setScroll(pos);
	}

	useEffect(()=>{
		window.addEventListener("scroll", onScroll);

		return ()=>{window.removeEventListener("scroll", onScroll);};
	}, [])

	return <Router>
		<ScrollReset />
		<ScrollContext.Provider value={scroll}>
			<Routes>
				<Route exact path="/" element={<Home />} />
				<Route exact path="/kohacoffee" element={<KohaCoffee />} />
			</Routes>
		</ScrollContext.Provider>
	</Router>
}
export default App;
export { ScrollContext };