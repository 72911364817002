import React from 'react';

import image from './kohacoffee.png';
import { Link } from 'react-router-dom';
const Carols = ()=>{
	return <div className="page">
		<div className="content">
			<div><img className="header-image" src={image} alt="Header for Carols Around the Tree" /></div>
			<div className="content-block">
				<header>Koha Coffee</header>
				<div>Drop by for a free coffee and baking on Wednesdays between 8:30 and 9:00am.  Tea or hot chocolate available for those who don't need a caffeine fix!</div>
				<div className="sub-header">Questions?</div>
				<div>Drop us a line <a href="mailto:petonestaugschurch@gmail.com?subject=Koha Coffee">petonestaugschurch@gmail.com</a></div>
				<div className="spacer"></div>
				<div><Link to="/">Back</Link></div>
			</div>

		</div>
	</div>
}

export default Carols;