import React, { useContext, useEffect, useRef, useState } from 'react';

import img from './kidsservice.png';
import { ScrollContext } from './App';

const ScrollingImage1 = ()=>{

	const [ translate, setTranslate ] = useState(0);

	const scroll = useContext(ScrollContext);
	const imgRef = useRef();

	const imgWidth = 898;
	const imgHeight = 275;

	useEffect(()=>{
		const bounds = imgRef.current.getBoundingClientRect();
		const windowHeight = window.innerHeight;
		const top = bounds.top;
		console.log(top-windowHeight);
		if (top - windowHeight > 0) {
			//We don't care
			return;
		}
		
		if (top < -imgHeight) {
			//We don't care
			return;
		}
		const movesUntil = -(windowHeight + imgHeight);
		const through = 1 - (movesUntil - (top - windowHeight)) / movesUntil;
		const start = (window.innerWidth > imgWidth) ?
			(window.innerWidth - 490) / -2 :
			window.innerWidth - imgWidth + 200;
		const end = (window.innerWidth > imgWidth) ?
			(window.innerWidth - 490) / -2 + (window.innerWidth - imgWidth) :
			-window.innerWidth / 2;
		const move = end - start;

			
		setTranslate(Math.round(start + move * through));
	}, [ scroll ]);

	return <div className="scroll-container">
		<div style={{left: translate}} className="scroll-inner">
			<img ref={imgRef} src={img} alt="Kid's Service" />
		</div>
	</div>
}

export default ScrollingImage1;