import React, { useContext, useEffect, useState } from 'react';
import { ScrollContext } from './App';

const Header = () => {

	const [ offset, setOffset ] = useState(0);

	//Grey
	const [ opacity, setOpacity ] = useState(0);
	const [ greyScroll, setGreyScroll ] = useState(window.scrollY);
	//const [ hideHeader, setHideHeader ] = useState(false);
// 	var velocity = 0.5;

// function update(){ 
// var pos = $(window).scrollTop(); 
// $('.container').each(function() { 
//     var $element = $(this);
//     // subtract some from the height b/c of the padding
//     var height = $element.height()-18;
//     $(this).css('backgroundPosition', '50% ' + Math.round((height - pos) * velocity) +  'px'); 
//    }); 
//    };



//  $(window).bind('scroll', update);

	const scroll = useContext(ScrollContext);

	useEffect(()=>{
		if (scroll === 0) {
			setOffset(0);
			setOpacity(0);
			return;
		}
		const height = window.innerHeight;

		if (scroll > height) {
			setOffset(40);
			setOpacity(1);
			setGreyScroll(scroll);
			return;
		}
		const factor = scroll / height * 40;
		setOffset(factor);
		const fadeStart = height - 220;
		const fadeEnd = height - 120;
		if (scroll > fadeStart && scroll < fadeEnd) {
			setOpacity((scroll - fadeStart)/100);
		} else if (scroll >= fadeEnd) {
			setOpacity(1);
		}
		
		setGreyScroll(scroll);
		//setHideHeader(scroll > (height - 120));
	}, [ scroll ]);

	return <div className="header">
		{/* <div className="header-under" style={{height: "calc(100% - " + (greyScroll) + "px)"}}>
			<div className="welcome">Welcome to</div>
			<div className="staugs">St Aug's</div>
		</div> */}
		<div className="header-bg" style={{top: -offset + "px", height: "calc(100% - " + scroll + "px)"}}/>
		<div className="header-grey" style={{top: -offset + "px", opacity, height: "calc(100% - " + greyScroll + "px)"}} />
		<div className="header-text" style={{height: "calc(100% - " + greyScroll + "px)", opacity: (1-opacity)}}>
			<div className="welcome">Welcome to</div>
			<div className="staugs">St Aug's</div>
		</div>
	</div>

}

export default Header;