import React from 'react';
import Header from './Header';
import logo from './staugs.svg';
import map from './map.png';
import thumbnail from './kohacoffee_thumbnail.png';
import { Link } from 'react-router-dom';
import ScrollingImage1 from './ScrollingImage1';

const Home = ()=>{
	return <div className="page">
		<Header />
		<div className="heading">
			St Augustine's Anglican Parish of Petone
		</div>
		<div className="sub-heading">
			Taking God's life, love and hope to our community
		</div>
		<div className="content">
			<div className="content-block">
				<img id="logo" src={logo} alt="" />
			</div>
			<div className="content-block">
				<header>Join us</header>
				<div>Join us for a service and stay for morning tea.</div>
				<div className="spacer" />
				<div>When you come through our doors you will
					find a mix of young and old people from many 
					continents and a warm welcome.</div>
				<div className="spacer" />
				<div className="spacer" />
				<div className="sub-header">Family Eucharist</div>
				<div>10am every Sunday<br />
					A vibrant service with children’s ministry.</div>
				<div className="spacer" />
				<div className="sub-header">1662 Book of Common Prayer</div>
				<div>10am the first Wednesday of every month<br />
					A traditional service</div>
				<div className="spacer" />
				<header>Find us</header>
				<div><a href="https://www.google.com/maps/place/St+Augustine's/@-41.2243675,174.8775804,17z/data=!3m1!4b1!4m6!3m5!1s0x6d38abbe51ad017b:0x979d0a8719acc8db!8m2!3d-41.2243675!4d174.8801553!16s%2Fg%2F1tjcpmrt?entry=ttu">
					<img src={map} alt="Map of the local area" />
				</a></div>
				<div>12 Brittania St<br />Petone</div>
				<div className="spacer" />
				<ScrollingImage1 />
				<div className="sub-header">Who are we?</div>
				<div>Beloved children of God. </div>
				<div className="mansplainer">We are always loved and accepted by our good heavenly Father.</div>
				<div className="sub-header">What are we doing here?</div>
				<div>Learning to be more like Jesus.</div>
				<div className="mansplainer">Getting to know him and learning to do the things he does.</div>
				<div className="sub-header">Where are we going?</div>
				<div>We are going deeper in loving relationship with God, one another and our neighbourhood.</div>
				<div className="mansplainer">We can only do this through the Holy Spirit</div>
				<div className="sub-header">Why bother?</div>
				<div>Because it makes a difference</div>
				<div className="spacer" />
				<header>What are we up to?</header>
				<Link to="/kohacoffee"><img src={thumbnail} alt="thumbnail of the Koha Coffee poster" /></Link>
				<div className="sub-header">We'd love to hear from you</div>
				<div className="sub-header">Email Us</div>
				<div><a href="mailto:petonestaugschurch@gmail.com">petonestaugschurch@gmail.com</a></div>
				<header>What else?</header>
				<div>We're currently working on our new website.  Come back soon for more content!  In the mean time you can 
					find out more at our old website <a href="https://petonestaugschurch.wordpress.com">https://petonestaugschurch.wordpress.com</a>
				</div>
				<div className="spacer" />
				<div className="spacer" />
				<div className="spacer" />
				<div className="spacer" />
			</div>
		</div>
	</div>
}

export default Home;